import Reports from "./reducer";
import ReportsSaga from "./saga";

import { registerRoutings } from "rootRouting";
import store from "rootStore";
import AsyncComponent from "ui/AsyncComponent";

interface IConfig {
    permissions: {
        read: boolean;
    };
    routing: Array<string>;
    url: string;
}

export const permissionKey = "report";

export const reportName = {
    categories: "event-category-sales",
    events: "event-sales",
    tickets: "event-ticket-sales",
    upsales: "event-upsales-sales",
    payments: "payments",
    paymentMethods: "payment-methods",
};
export const urls = {
    baseUrl: "/reports",
    eventReportUrl: "/reports/events",
    categoryReportUrl: "/reports/event-categories",
    ticketReportUrl: "/reports/event-tickets",
    upsalesReportUrl: "/reports/event-upsales",
    paymentMethodReportUrl: "/reports/payment-methods",
    paymentReportUrl: "/reports/payments",
};

const getConfig = (permissions: Array<string>) => {
    const modulePermissions = permissions.filter(
        (item) => item.indexOf(permissionKey) === 0
    );

    const url = urls.baseUrl;

    const config: IConfig = {
        permissions: {
            read: false,
        },
        routing: [],
        url,
    };

    let routing = [];
    if (modulePermissions.indexOf(permissionKey + ".read") !== -1) {
        config.permissions.read = true;
        store.injectSaga?.("ReportSaga", ReportsSaga);
        store.reducerManager?.add("Reports", Reports);

        routing.push(
            {
                path: urls.eventReportUrl,
                element: (
                    <AsyncComponent path="Reports/Event/pages/EventsReportIndexPage" />
                ),
            },
            {
                path: urls.categoryReportUrl,
                element: (
                    <AsyncComponent path="Reports/Event/pages/CategoriesReportIndexPage" />
                ),
            },
            {
                path: urls.ticketReportUrl,
                element: (
                    <AsyncComponent path="Reports/Event/pages/TicketsReportIndexPage" />
                ),
            },
            {
                path: urls.upsalesReportUrl,
                element: (
                    <AsyncComponent path="Reports/Event/pages/UpsalesReportIndexPage" />
                ),
            },
            {
                path: urls.paymentReportUrl,
                element: (
                    <AsyncComponent path="Reports/Payment/pages/PaymentReportIndexPage" />
                ),
            },
            {
                path: urls.paymentMethodReportUrl,
                element: (
                    <AsyncComponent path="Reports/Payment/pages/PaymentMethodReportIndexPage" />
                ),
            }
        );
    }

    if (routing.length > 0) {
        config.routing = routing.map((item) => item.path);
        registerRoutings(routing);
    }

    return config;
};

export default getConfig;
