import { api } from "apiConnections";
import { AxiosRequestConfig } from "axios";
import RequestUrlId from "types/RequestUrlId";

const baseUrl = "/reports";

const requests = {
    reportFetch: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`${baseUrl}/${url.id}`, config),
    export: (config: AxiosRequestConfig, url: RequestUrlId) =>
        api.get(`/reports/${url.id}/csv`, {
            ...config,
            responseType: "blob",
        }),
};

export default requests;
