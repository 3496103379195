

import { tableUpdate } from "helpers/reducers";
import { omit } from "lodash";
import moment from "moment";
import { AnyAction } from "redux";
import { TABLE_UPDATE } from "./types";

interface IReports {
    id: string;
    filters: any;
    periodType: string;
}
function ReportsReducer(
    state: IReports = {
        id: "Reports",
        filters: {
            created_at_period_filter: {
                after: moment().local().format("YYYY-MM-DD"),
                before: moment().local().format("YYYY-MM-DD"),
            },
        },
        periodType: "today",
    },
    action: AnyAction
) {
    switch (action.type) {
        case TABLE_UPDATE:
            return {
                ...tableUpdate(state, omit(action, "periodType")),
                periodType: action.data?.periodType || state.periodType,
            };

        default:
            return state;
    }
}

export default ReportsReducer;
