import { removeDiacritics } from "./diacritics";

const currencies = {
    NOK: "kr",
    SEK: "kr",
};

// declare global {
//     interface Number {
//         format(n: number, x?: number): string;
//         price(currency: string, position: string, format: number): string;
//     }

//     interface String {
//         removeDiacritics(): string;
//     }
// }

// eslint-disable-next-line no-extend-native
Number.prototype.format = function (n, x) {
    var re = "\\d(?=(\\d{" + (x || 3) + "})+" + (n > 0 ? "\\." : "$") + ")";
    return this.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, "g"), "$& ");
};

// eslint-disable-next-line no-extend-native
Number.prototype.price = function (
    currency,
    position = "right",
    format = 2,
    code = false
) {
    let currencySymbol = code ? currency : currencies[currency];
    let value = this;

    if (isNaN(this)) {
        value = 0;
    }

    value = value / 100;

    if (currency === undefined) {
        return value.format(format);
    }

    if (position === "right")
        return value.format(format) + " " + currencySymbol;
    else return currencySymbol + " " + value.format(format);
};

// eslint-disable-next-line no-extend-native
String.prototype.removeDiacritics = function () {
    return removeDiacritics(this);
};

// eslint-disable-next-line no-extend-native
String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

// eslint-disable-next-line no-extend-native
String.prototype.toCamelCase = function () {
    return this.toLowerCase().replace(/([-_][a-z])/g, (group) =>
        group.toUpperCase().replace("-", "").replace("_", "")
    );
};
