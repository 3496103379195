export type TTableActions = {
    id: string;
};

export type TColumn = {
    headName: string;
    field: string;
    cellProps: {
        align: "center" | "left" | "right" | "inherit" | "justify" | undefined
    }
};
export const TABLE_UPDATE = "REPORT_TABLE_UPDATE/TABLE_UPDATE";

const types = {
    EXPORT_FAILED: "REPORTS/EXPORT_FAILED",
    EXPORT_REQUEST: "REPORTS/EXPORT_REQUEST",
    EXPORT_SUCCESS: "REPORTS/EXPORT_SUCCESS",

    REPORTS_FETCH_REQUEST: "REPORTS/REPORTS_FETCH_REQUEST",
    REPORTS_FETCH_SUCCESS: "REPORTS/REPORTS_FETCH_SUCCESS",
    REPORTS_FETCH_FAILED: "REPORTS/REPORTS_FETCH_FAILED",

    TABLE_UPDATE,
};

export default types;
