import BarcodeReader from "app/BarcodeReader/config";
import Bookings from "app/Bookings/config";
import Categories from "app/Categories/config";
import Dashboard from "app/Dashboard/config";
import Events from "app/Events/config";
import Languages from "app/Languages/config";
import ManageTables from "app/ManageTables/config";
import Permissions from "app/Permissions/config";
import WebEvent from "app/WebEvent/config";
import Payments from "app/Payments/config";
import Reports from "app/Reports/config";
import Roles from "app/Roles/config";
import Settings from "app/Settings/config";
import Upsales from "app/Upsales/config";
import Users from "app/Users/config";

const serviceProviders = {
    Dashboard,
    Bookings,
    BarcodeReader,
    Events,
    Categories,
    Upsales,
    Settings,
    Languages,
    Payments,
    Users,
    Permissions,
    Reports,
    Roles,
    ManageTables,
    WebEvent,
};

export default serviceProviders;
