import api from "./api";
import types from "./types";

import { request } from "api/apiSaga";
import { snackbarShow } from "app/App/actions";
import { downloadFromRequest } from "helpers/download";
import trans from "helpers/trans";
import { AnyAction } from "redux";
import { call, put, takeLatest } from "redux-saga/effects";

function* reportFetch(action: AnyAction) {
    yield call(request, api.reportFetch, action);
}

function* reportExport(action: AnyAction): any {
    const response = yield call(request, api.export, action);
    if (response.status === 200) {
        downloadFromRequest(response);
        yield put(
            snackbarShow({
                content: trans("event.reports.saga.content.export"),
            })
        );
    }
}

export default function* saga() {
    yield takeLatest(types.REPORTS_FETCH_REQUEST, reportFetch);
    yield takeLatest(types.EXPORT_REQUEST, reportExport);
}
